import React from "react";
import Cache2Controller from "./Cache2Controller.web";
import {
  Container,
  Box,
  Input,
  Paper,
  Typography,
  FormLabel,
  Divider,
  NativeSelect,
  Checkbox,
  Button,
  TextField,
  styled
} from "@material-ui/core";
import {
  Settings,
  Search,
  Clear,
  CalendarTodayRounded,
} from "@material-ui/icons";
import { createTheme, ThemeProvider, createStyles, withStyles } from "@material-ui/core/styles";
import Settings5 from "../../Settings5/src/Settings5.web";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Cache2Web.css";
import Backdrop from "@material-ui/core/Backdrop";
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckIcon from '@material-ui/icons/Check';
import { size } from "lodash";



const CheckiconBox = styled(Box)({
  '& >span.Mui-checked': {
    padding: '12px!important'
  },
})

export const CustomTextField = styled(TextField)(({ active }: any) => ({
  '& input': {
    padding: '0px 4px !important'
  },
  '& > div': {
    minHeight: '26px',
    marginLeft: '5px',
    paddingTop: '0px!important',
    backgroundColor: 'rgb(38, 38, 38) !important',
  },
  '& > div:before, & > div:after': {
    borderBottom: active ? 'none !important' :'1px solid gray !important',
  },
}));

export const CustomTextFields = styled(TextField)(({ active }: any) => ({
  '& input': {
    padding: '0px 4px !important',
  },
  '& > div:before, & > div:after': {
    borderBottom: active ? 'none !important' :'1px solid rgba(0, 0, 0, 0.42) !important',
  },
  '& > div': {
    marginLeft: '5px',
    minHeight: '26px',
    paddingTop: '0px !important',
    backgroundColor: '#f3f1f1 !important',
  },
}));


export class AppHeader extends Cache2Controller {

  /* istanbul ignore next */
  sendDataToParent = () => {
    if (!size(this.state.from) && this.state.tempFrom) {
      this.setState({ from: [this.state.tempFrom] })
    }
    if (!size(this.state.to) && this.state.tempTo) {
      this.setState({ to: [this.state.tempTo] })
    }
    const data = {
      // "user_name": "carly.simon",
      // "user_password": "P@55word",
      user_name: this.state.username,
      user_password: this.state.password,
      startIndex: 0,
      maxResults: 10,
      has_word: this.state.has_word,
      subject: this.state.subject,
      from: !size(this.state.from) && this.state.tempFrom ? [this.state.tempFrom] : this.state.from,
      to: !size(this.state.to) && this.state.tempTo ? [this.state.tempTo] : this.state.to,
      has_attachment: this.state.has_attachment,
      size: this.state.size,
      date: this.state.date,
      size_unit: this.state.size_unit || "kb",
      size_condition: this.state.size_condition || ">",
      days: this.state.days || "1",
    };

    this.setState({ onClickAdvanceSearch: false });
    // Call the callback function passed from the parent with the data as an argument
    this.props.onDataReceived(data);
    // this.props.normalDetailArray(data)
  };
  sendDatatoChildForNormalSearch = (data: any) => {
    this.props.normalDetailArray(data);
    this.setState({ isOpen: false });
  };
  /* istanbul ignore next */
  generateOptions(optionsArray: any) {
    if (!optionsArray) return [];
    return optionsArray.map((option: any) => option);
  }
  /* istanbul ignore next */
  generateOptionsSecond(optionsArray: any) {
    if (!optionsArray) return
    return optionsArray.map((option: any) => option);
  }

  /* istanbul ignore next */
  renderInlineSubject = () => {
    return !this.state.subject ? webStyles.subjectInline : { alignItems: 'center', marginBlock: '5px' }
  }

  /* istanbul ignore next */
  render() {
    const maxSelectableDate = new Date();
    const { classes } = this.props;
    const isDark = this.props.isDark;
    const themeStyles = isDark ? darkStyles : lightStyles;
    const Type = () => {
      if (isDark) {
        return "dark";
      }
      else {
        return "light";
      }
    }
    const PaperData = () => {
      if (isDark) {
        return "#0d0d0d";
      }
      else {
        return "#fffff";
      }
    }
    const theming = createTheme({
      palette: {
        primary: {
          main: "#fff",
        },
        secondary: { main: "#050505" },
        type: Type(),
        background: {
          default: "#ffffff",
          paper: PaperData(),
        },
      },
    });

    return (
      <ThemeProvider theme={theming}>
        <Paper>
          <Container
            maxWidth="xl"
            style={{ ...styles.contianer, ...themeStyles.border }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  gridGap: "20px",
                }}
              >
                <Box
                  sx={{
                    border: "1px solid grey",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 10px",
                    borderRadius: "5px",
                    height: "35px",
                    alignItems: "center",
                  }}
                >
                  <Input
                    data-test-id="searchInputText"
                    disableUnderline={true}
                    placeholder="Search"
                    value={this.state.searchTerm}
                    style={{ width: "500px" }}
                    onChange={this.handleInputChange}
                    onClick={() => this.toggleSearchBox()}
                  />
                  <Search />
                </Box>

              </Box>

              <Box
                sx={{
                  display: "flex",
                  gridGap: "20px",
                  alignItems: "center",
                }}
              >
                <Settings
                  onClick={this.handleSettingsPop}
                  data-test-id="settingButton"
                  style={{ cursor: "pointer" }}
                />
                {this.state.settingsPopOpen && (
                  <>
                    <Backdrop style={{ background: "transparent", zIndex: 1300 }} open={this.state.settingsPopOpen} onClick={() => this.setState({ settingsPopOpen: false })} data-test-id="settingModal">
                      <Settings5
                        navigation={this.props.navigation}
                        dataSyncPostAPI={(e: any) => this.dataSyncPostAPI(e)}
                        dataSyncState={this.state.dataSyncState}
                        id=""
                        drawer={{
                          navigation: {
                            toggleDrawer: () => ({}),
                          },
                          route: {
                            name: "",
                          },
                        }}
                        data-test-id="settingsTest"
                      />
                    </Backdrop>
                  </>
                )}

                <Box
                  sx={{
                    height: "35px",
                    width: "35px",
                  }}
                >
                  <img
                    src="https://banner2.kisspng.com/20180615/rtc/kisspng-avatar-user-profile-male-logo-profile-icon-5b238cb002ed52.870627731529056432012.jpg"
                    alt="profile_img"
                    width="100%"
                    style={{ borderRadius: "50%" }}
                  />
                </Box>
              </Box>
            </Box>
            {this.state.isOpen && (
              <>
                <Backdrop style={{
                  background: "transparent", zIndex: 1300
                }} open={this.state.isOpen} onClick={() => this.setState({
                  isOpen: false, onClickAdvanceSearch: false, advanceSearchPop: false
                })} data-test-id="backdropBtn">

                  <Box
                    //onMouseLeave={this.toggleCard}
                    sx={{
                      position: "absolute",
                      top: "45px",
                      zIndex: 1,
                      left: "14%"
                    }}
                    // style={{...themeStyles.container}}
                    data-test-id="backdropStopPropagation"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Paper
                      elevation={3}
                      variant="outlined"
                      square
                      style={{
                        width: "542px",
                        height: "330px",
                        marginTop: "2px",
                        overflowY: "auto",
                        overflowX: "hidden",
                        ...themeStyles.container,
                      }}
                    >

                      <Box sx={searchCard.mainCard}>
                        <Box sx={searchCard.box1}>
                          <Typography>Best Matches</Typography>
                          <Typography
                            onClick={this.handleAdvanceSearchPop}
                            data-test-id="advanceSearchLink"
                            style={{
                              cursor: "pointer",
                              ...themeStyles.advanceSearchColor,
                            }}
                          >
                            Advanced Search
                          </Typography>
                        </Box>
                        {this.state.normalSearchArray.length > 0
                          ? this.state.normalSearchArray.map((ele: any, index: any) => (
                            <Box
                              key={index}
                              data-test-id="normalSearch"
                              style={searchCard.box2}
                              onClick={() =>
                                this.sendDatatoChildForNormalSearch(ele)
                              }
                            >
                              <Box
                                style={{
                                  marginRight: "7px",
                                  border: "1px solid #68686b",
                                  borderRadius: "50%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "2em",
                                  width: "2em",
                                  color: "white",
                                  boxSizing: "border-box",
                                  backgroundColor: "rgb(166, 179, 238)",
                                  paddingTop: "4px",
                                }}
                              >
                                {ele.attributes.author[0].toUpperCase()}
                              </Box>
                              <Box sx={searchCard.box3}>
                                <Typography
                                  style={{ fontSize: "1em", fontWeight: 400 }}
                                >
                                  {ele.attributes.author}
                                </Typography>
                                <Typography style={{ fontSize: "0.6em" }}>
                                  {ele.attributes.sender_email}
                                </Typography>
                              </Box>
                            </Box>
                          ))
                          : <Typography style={{ textAlign: "center" }}>No Data</Typography>}
                        <Box>
                          {this.state.normalSearchArray.length > 0 ? (
                            <Box style={{ marginBottom: "7px" }}>
                              Suggested Search
                            </Box>
                          ) : null}

                          {this.state.normalSearchArray.length > 0
                            ? this.state.normalSearchArray.map((ele: any, index: any) => (
                              <Box
                                key={index}
                                style={searchCard.box2}
                                onClick={() =>
                                  this.sendDatatoChildForNormalSearch(ele)
                                }
                                data-test-id="normalSearch"
                              >
                                <Box
                                  style={{
                                    border: "none",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "#DCDCDC",
                                    width: "2em",
                                    height: "2em",
                                    boxSizing: "border-box",
                                    paddingTop: "4px",
                                    color: "white",
                                    marginRight: "7px",
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="black"
                                    className="w-6 h-6"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                    />
                                  </svg>
                                </Box>
                                <Box sx={searchCard.box3}>
                                  <Typography
                                    style={{ fontSize: "1em", fontWeight: 400 }}
                                  >
                                    {ele.attributes.author}
                                  </Typography>
                                </Box>
                              </Box>
                            ))
                            : null}
                        </Box>
                      </Box>

                    </Paper>
                  </Box>
                </Backdrop></>
            )}

            {this.state.onClickAdvanceSearch && this.state.advanceSearchPop ? (
              <>
                <Backdrop style={{
                  background: "transparent", zIndex: 1300
                }} open={this.state.advanceSearchPop} onClick={() => this.setState({
                  onClickAdvanceSearch: false, advanceSearchPop: false
                })} data-test-id="backdropAdvance">

              <Box
                style={{
                  position: "absolute",
                  top: "12%",
                  left: "calc(50% - 300px)",
                  width: "400px",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  maxHeight: "85vh", 
                  overflowY: "auto",
                  ...themeStyles.container,
                }}
                data-test-id="backdropStopPropagationAdvance"
                onClick = {(e)=>  e.stopPropagation()}
              >
                <Box style={webStyles.title_box} data-test-id="inputFromText">
                  <Typography style={{ fontWeight: 700, fontSize: "16px"}}>
                    Advanced Search
                  </Typography>
                  <Clear
                    {...this.props}
                    onClick={() =>{
                      this.setState({ onClickAdvanceSearch: false })
                      this.clearFilterAdvanceSearch()
                    }
                    }
                    style={{ cursor: "pointer",width:'50px' }}
                    data-test-id="btnCloseModal"
                  />
                </Box>
                <Divider style={{ width: "93%", margin: "auto", height: 2 }} />
                <Box
                  style={{
                    width: "90%",
                    padding: "15px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "17px",
                  }}
                >
                      <Box style={{ ...webStyles.form_control_wrapper }}>
                        <FormLabel
                          htmlFor=""
                          style={{
                            ...webStyles.form_label,
                            ...themeStyles.form_label,
                            marginRight: "0.2em",
                            minWidth: 'max-content'
                          }}
                        >
                          From :
                        </FormLabel>
                        {isDark ? (
                          <div className={classes.root}>
                            <Autocomplete
                              open={false}
                              multiple
                              freeSolo
                              disableClearable
                              options={this.generateOptions(this.state.from)}
                              id="tags-filled"
                              data-test-id="autocompletedata"
                              style={webStyles.firstAuto}
                              onChange={(event, newValue) => {
                                this.setState({ from: newValue })
                              }}
                              onInputChange={(event, newValue) => {
                                this.setState({ tempFrom: newValue })
                              }}
                              value={this.state.from}
                              renderTags={(value: string[], getTagProps) =>
                                value.map((option: string, index: number) => {
                                  const { onDelete, ...tagProps } = getTagProps({ index }) as any;

                                  return (
                                    <Chip style={{backgroundColor: '#363636', border: "1px solid gray", height: '26px'}} label={option} {...tagProps}
                                    />
                                  )
                                })
                              }
                              renderInput={(params) => (
                                <CustomTextField active={this.state.from.length > 0} style={{ borderRadius: '20px', border: 'none', outline: 'none', fontSize: "14px" }} {...params} variant="filled" />
                              )}

                            />
                          </div>) : (
                          <div className={classes.root}>
                            <Autocomplete
                              open={false}
                              multiple
                              disableClearable
                              id="tags-filled"
                              options={this.generateOptions(this.state.from)}
                              freeSolo
                              style={advanceBox.secondAuto}
                              onChange={(event, newValue) => {
                                this.setState({ from: newValue })
                              }}
                              onInputChange={(event, newValue) => {
                                this.setState({ tempFrom: newValue })
                              }}
                              value={this.state.from}
                              renderTags={(values: string[], getTagProps) =>
                                values.map((options: string, index: number) => {
                                  const { onDelete, ...tagProps } = getTagProps({ index }) as any;

                                  return (
                                    <Chip style={{border: "1px solid #363636", height: '26px'}} label={options} {...tagProps}
                                    />
                                  )
                                })
                              }
                              renderInput={(allparams) => (
                                <CustomTextFields
                                  active={this.state.from.length > 0}
                                  style={{ borderColor: "black", fontSize: "14px" }}
                                  {...allparams} variant="filled" />
                              )}

                            />
                          </div>
                        )}

                      </Box>
                      <Box style={{ ...webStyles.form_control_wrapper }}>
                        <FormLabel
                          style={{
                            ...webStyles.form_label,
                            ...themeStyles.form_label,
                            marginRight: "0.2em",
                            minWidth: 'max-content',
                          }}
                          htmlFor=""
                        >
                          To :
                        </FormLabel>
                        {isDark ? (
                          <div className={classes.root}>
                            <Autocomplete
                              open={false}
                              id="tags-filled"
                              style={lightStyles.thirdAuto}
                              disableClearable
                              freeSolo
                              multiple
                              options={this.generateOptionsSecond(this.state.to)}
                              renderTags={(value: string[], getTagProps) =>
                                value.map((option: string, index: number) => {
                                  const { onDelete, ...tagProps } = getTagProps({ index }) as any;

                                  return (
                                    <Chip style={{backgroundColor: '#363636', border: "1px solid gray", height: '26px'}} label={option} {...tagProps} />
                                  )
                                })
                              }
                              onChange={(event, newValue) => {
                                this.setState({ to: newValue })
                              }}
                              onInputChange={(event, newValue) => {
                                this.setState({ tempTo: newValue })
                              }}
                              value={this.state.to}
                              renderInput={(params) => (
                                <CustomTextField active={this.state.to.length > 0} {...params} variant="filled" style={{ fontSize: "14px" }} />
                              )}

                            />
                          </div>) : (
                          <div className={classes.root}>
                            <Autocomplete
                              open={false}
                              multiple
                              disableClearable
                              style={{ backgroundColor: '#ffffff !important', minHeight: '32px' }}
                              options={this.generateOptionsSecond(this.state.to)}
                              freeSolo
                              onChange={(event, newValue) => {
                                this.setState({ to: newValue })
                              }}
                              onInputChange={(event, newValue) => {
                                this.setState({ tempTo: newValue })
                              }}
                              value={this.state.to}
                              id="tags-filled"
                              renderTags={(value: string[], getTagProps) =>
                                value.map((option: string, index: number) => {
                                  const { onDelete, ...tagProps } = getTagProps({ index }) as any;

                                  return (
                                    <Chip style={{border: "1px solid #363636", height: '26px'}} label={option} {...tagProps} />
                                  )
                                })
                              }
                              renderInput={(params) => (
                                <CustomTextFields active={this.state.to.length > 0}  {...params} variant="filled" style={{ fontSize: "14px" }} />
                              )}

                            />
                          </div>
                        )}
                      </Box>
                      <div style={this.renderInlineSubject()}>
                        <div>
                          <FormLabel
                            htmlFor=""
                            style={{
                              ...webStyles.form_label,
                              ...themeStyles.form_label,
                              marginRight: '0.2rem'
                            }}
                          >
                            Subject :
                          </FormLabel>
                        </div>
                        <div style={{ flex: 1, marginLeft: '5px' }}>
                        <TextField
                            name="subject"
                            style={{
                              ...webStyles.input_style,
                              ...themeStyles.form_label,
                              fontSize: "16px",
                              width: '100%',
                              padding: "0px",
                              paddingLeft: "8px",
                              borderBottom: this.state.subject ? 'none' : "1px solid grey",
                            }}
                            multiline
                            InputProps={{
                              disableUnderline: true,
                              style: { paddingBottom: '2px' }
                            }}
                            value={this.state.subject}
                            data-test-id="inputSubjectText"
                            onChange={this.handleInput}
                          />
                        </div>
                      </div>
                      <Box style={{ ...webStyles.form_control_wrapper, overflowX: 'hidden', marginBlock: '5px' }}>
                        <FormLabel
                          htmlFor=""
                          style={{
                            ...webStyles.form_label,
                            ...themeStyles.form_label,
                            minWidth: 'max-content',
                            marginRight: '0.2rem'
                          }}
                        >
                          Has the words :
                        </FormLabel>
                        <input
                          type="text"
                          name="has_word"
                          style={{
                            ...webStyles.input_style,
                            ...themeStyles.form_label,
                            fontSize: "16px",
                            paddingLeft: "8px",
                            marginLeft: "5px",
                          }}
                          // InputProps={{
                          //   disableUnderline: true,
                          // }}
                          data-test-id="inputHadWordsText"
                          onChange={this.handleInput}
                          value={this.state.has_word}
                        />
                      </Box>
                      <Box style={{...webStyles.form_control_wrapper, marginBlock: '5px'}}>
                        <FormLabel
                          htmlFor=""
                          style={{
                            ...webStyles.form_label,
                            ...themeStyles.form_label,
                            minWidth: 'max-content',
                            marginRight: '15px'
                          }}
                        >
                          Size :
                        </FormLabel>

                        <NativeSelect
                          style={{
                            fontSize: "14px",
                            width: "58%",
                            borderBottom: "1px solid grey",
                          }}
                          defaultValue=">"
                          inputProps={{
                            style: { ...webStyles.spaceAdd },
                            id: "uncontrolled-native",
                          }}
                          disableUnderline
                          onChange={this.handleInput}
                          name="size_condition"
                          value={this.state.size_condition}
                        >
                          <option value=">">greater than</option>
                          <option value="<">less than</option>
                        </NativeSelect>
                        <Box style={{ width: "40%", }}>

                          <input
                            type="tel"
                            style={{
                              ...webStyles.input_style,
                              ...themeStyles.form_label,
                              padding: "5px 5px",
                              paddingBottom: "8px",
                              border: "none",
                              backgroundColor: "transparent",
                              borderBottom: "1px solid grey",
                              outline: "none",
                              width: '70%',
                              marginLeft: '12%',
                              marginRight: '2%'

                            }}
                            onChange={this.handleInput}
                            name="size"
                            value={this.state.size}
                          // InputProps={{
                          //   disableUnderline: false,
                          // }}
                          />
                        </Box>

                        <NativeSelect
                          style={{
                            fontSize: "14px",

                            borderBottom: "1px solid grey",
                            width: "25%",
                            // color: "grey",
                          }}
                          defaultValue="MB"
                          name="size_unit"
                          value={this.state.size_unit}
                          inputProps={{
                            id: "uncontrolled-native",
                            style: { ...webStyles.spaceAdd }
                          }}
                          disableUnderline
                          onChange={this.handleInput}
                        >
                          <option value="KB">KB</option>
                          <option value="MB">MB</option>
                        </NativeSelect>
                      </Box>
                      <Box style={{...webStyles.form_control_wrapper, marginBlock: '4px'}}>
                        <FormLabel
                          style={{
                            ...webStyles.form_label,
                            ...themeStyles.form_label,
                            minWidth: 'max-content',
                          }}
                        >
                          Date with in :
                        </FormLabel>

                        <NativeSelect
                          style={{
                            fontSize: "14px",
                            width: "30%",
                            borderBottom: "1px solid grey",
                            // color: "grey",
                          }}
                          defaultValue="1day"
                          inputProps={{
                            id: "uncontrolled-native",
                            style: { ...webStyles.spaceAdd }
                          }}
                          variant="standard"
                          disableUnderline
                          name="days"
                          value={this.state.days}
                          onChange={this.handleInput}
                          data-test-id="selectDateDropdown"
                        >
                          <option value="1">1 day</option>
                          <option value="7">7 days</option>
                          <option value="30">30 days</option>
                          <option value="60">60 days</option>
                        </NativeSelect>
                        <Box
                          style={{
                            ...webStyles.input_date,
                            ...themeStyles.form_label,
                          }}
                        >
                          <Typography style={{ fontSize: "14px" }}>
                            {this.state.date}
                          </Typography>
                          <CalendarTodayRounded
                            onClick={this.handleDatePicker}
                            style={{
                              ...themeStyles.boxColor,
                              zIndex: 9999999,
                              fontSize: "15px",
                              cursor: "pointer",
                              marginBottom: "5px",
                            }}
                            data-test-id="calendarOpen"
                          //onChange={this.handleInput}
                          // name="date"
                          />
                          {this.state.datePickerOpen ? (
                            <Box
                              style={{
                                position: "absolute",
                                zIndex: 99999,
                                backgroundColor: "black",
                                top: "67%",
                                left: "0",
                              }}
                              data-test-id="calenderBox"
                            >
                              <Calendar
                                // name="date"
                                onChange={this.handleDateChange}
                                // value={this.state.selectedDate}
                                value={this.state.date}
                                maxDate={maxSelectableDate}
                                // style={webStyles.calendar}
                                data-test-id="dateChange"
                              />
                            </Box>
                          ) : null}
                        </Box>
                      </Box>
                      <Box style={{...webStyles.form_control_wrapper, marginBlock: '5px'}}>
                        <FormLabel
                          style={{
                            ...webStyles.form_label,
                            ...themeStyles.form_label,
                            marginRight: '15px'
                          }}
                        >
                          Search :
                        </FormLabel>

                        <NativeSelect
                          style={{
                            fontSize: "14px",
                            flexGrow: 1,
                            borderBottom: "1px solid grey",
                            // color: "grey",
                          }}
                          defaultValue="inbox"
                          inputProps={{
                            id: "uncontrolled-native",
                            style: { ...webStyles.spaceAdd }
                          }}
                          disableUnderline
                          onChange={this.handleInput}
                          name="search"
                        >
                          {/* <option value="inbox">All Inboxes</option>
                    <option value="drafts">drafts</option> */}
                          <option value="David@vault.com">David@vault.com</option>
                        </NativeSelect>
                      </Box>
                      <CheckiconBox
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          onChange={this.handleInput}
                          name="has_attachment"
                          checked={this.state.has_attachment}
                          checkedIcon={<CheckIcon style={{
                            ...themeStyles.check_box_color,
                            borderRadius: 3, fontSize: 15
                          }} />}
                          style={{ ...themeStyles.boxColor }}
                        />
                        <FormLabel
                          style={{
                            ...webStyles.form_label,
                            ...themeStyles.form_label,
                            marginLeft: '-5px'
                          }}
                        >
                          Has attachment
                        </FormLabel>
                      </CheckiconBox>
                      <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          margin: "auto",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <button
                          style={
                            isDark
                              ? webStyles.create_btn_dark
                              : webStyles.create_btn
                          }
                          onClick={this.clearFilterAdvanceSearch}
                        >
                          Clear filter
                        </button>
                        <button
                          style={webStyles.search_btn}
                          data-test-id="btnGetAdvancedSearchList"
                          onClick={this.sendDataToParent}
                        >
                          Search
                        </button>
                      </Box>
                    </Box>
                  </Box>
                </Backdrop> </>
            ) : null}

          </Container>
        </Paper>
      </ThemeProvider>
    );
  }
}

const lightStyles = {
  advanceSearchColor: {
    color: "black"
  },
  border: { borderBottom: "1px solid #f3f3fd" },
  container: {
    // backgroundColor: "#f6f7f9",
    backgroundColor: "#f3f1f1",
    //boxShadow: "4px 4px 20px 2px #ebebeb",
  },
  boxColor: {
    color: "black"
  },
  check_box_color: {
    color: 'yellow',
    border: '2px solid yellow',
    backgroundColor: "black",
  },
  form_label: {
    color: "black",
  },
  thirdAuto: {
    backgroundColor: 'rgb(38, 38, 38) !important',
    minHeight: '32px'
  }
};
const darkStyles = {
  advanceSearchColor: {
    color: "yellow"
  },
  border: {
    borderBottom: "1px solid #2c2c2c",
    //borderBottom: "1px solid white"
  },
  boxColor: {
    color: "white"
  },
  check_box_color: {
    color: 'yellow',
    border: '2px solid yellow',
  },
  container: {
    // backgroundColor: "#292c31",
    backgroundColor: "#262626",
    border: "none",
  },
  form_label: {
    color: "white",
  },

  ccard: {
    backgroundColor: "black",
    border: "2px solid black",
  },
};
const styles = {
  contianer: {
    padding: "10px 15px",
    // width: "90%",
  },

};
const searchCard = {
  mainCard: {
    boxSizing: "border-box",
    width: "542px",
    padding: "20px",
    // height: "200px",
    height: "330px",
  },
  box1: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: "7px",
    marginRight: "5px",
  },
  box2: {
    display: "flex",
    // marginBottom: "7px",
    marginBottom: "15px",
    alignItems: "center",
    cursor: "pointer",
  },
  box3: {
    display: "flex",
    flexDirection: "column",
  },
};
const modalStyle = {
  backgroundColor: "#f3f1f1",
};
const webStyles = {
  subjectInline: {
    display: 'flex',
    alignItems: 'center',
    marginBlock: '5px'
  },
  spaceAdd: {
    paddingLeft: "5px"
  },
  container: {
    position: "absolute",
    left: "40%",
    width: "350px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    top: "12%",
    backgroundColor: '#f3f1f1'
  },
  container_dark: {
    width: "350px",
    left: "40%",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    top: "12%",
    backgroundColor: "#292c31",
    position: "absolute",
  },
  title_box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 15px",
  },
  form_wrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "15px",
    gap: "17px",
    width: "90%",
  },
  form_control_wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  form_label: {
    color: "black",
    fontSize: "14px",
  },
  // form_label_dark: { color: "white", fontSize: "14px" },
  input_style: {
    flexGrow: 1,
    border: "none",
    backgroundColor: "transparent",
    borderBottom: "1px solid grey",
    padding: "0px 3px 3px 0px",
    outline: "none",
  },
  input_date: {
    marginTop: "8px",
    width: "35%",
    paddingBottom: '2px',
    fontSize: "10px",
    borderBottom: "1px solid grey",
    // color: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  create_btn: {
    borderRadius: "45px",
    // backgroundColor: "#ffffff",
    backgroundColor: "white",
    fontSize: "14px",
    padding: "8px",
    // textTransform: "none",
    fontWeight: 600,
    width: "45%",
    border: "none",
  },
  create_btn_dark: {
    backgroundColor: "#363636",
    padding: "8px",
    borderRadius: "45px",
    fontSize: "14px",
    width: "45%",
    border: "none",
    // textTransform: "none",
    fontWeight: 600,
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#424040",
    },
  },
  search_btn: {
    width: "45%",
    color: "#5b5c5e",
    padding: "8px",
    backgroundColor: "#F5F202",
    fontSize: "14px",
    borderRadius: "45px",
    border: "none",
    // textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#f0ed47",
      color: "#5b5c5e",
    },
  },
  customButton: {
    fontSize: "12px",
    padding: "0px",
  },
  calendarPopover: {
    "& .MuiPaper-root-641": {
      backgroundColor: "red !important",
      /* Add more styles as needed */
    },
  },
  calendar: {
    backgroundColor: "white",
  },
  firstAuto: {
    backgroundColor: 'rgb(38, 38, 38) !important',
    minHeight: '32px'
  }
};
const advanceBox = {
  container: {
    display: "flex",
    flexDirection: "row",
  },
  secondAuto: {
    backgroundColor: '#f3f1f1 !important',
    minHeight: '32px'
  }
};

export const useStyles: any = (theme: any) => createStyles({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(0),

    },
  },


})

export default withStyles(useStyles)(AppHeader as any) as any




