import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import secureLocalStorage from "react-secure-storage";
import { Response } from "./Cache2Controller";
import { ThemeContext } from "../../../framework/src/ThemeContext/ThemeProvider";
export const configJSON = require("./config");
let baseURL = require("../../../framework/src/config");
import React from "react";
import moment from "moment";
import { size, get, find, uniqBy } from 'lodash'

const RESULTS = 20

export interface Props {
  navigation?: any;
  id?: string;
  isDark?: boolean;
  searchData?: Object;
  handleSearchTerm?: any;
  open?: boolean;
  advancedsearchList?: [];
  dataEmail?: any;
  handleAdvanceSearchPop?: any;
  onDataReceived?: any;
  timestamp?: any;
  clearFilterAdvanceSearch?: any;
  normalDetailArray?: any;
  flag?: any;
  dataSyncState?: any;
  dataSyncPostAPI?: any;
  classes?: any;
}


export interface EmailRoot {
  data: EmailData[];
}

interface IApiInterceptor {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: string;
}

export interface EmailData {
  id: string;
  type: string;
  attributes: EmailDataAttributes;
}

export interface EmailDataAttributes {
  uid: string;
  save_set_id: string;
  vault_id: string;
  author: string;
  sent_dt: string;
  recipient_string: string;
  subject: string;
  importance: number;
  has_attatchments: boolean;
  conversation_topic: string;
  body: string;
  sender_email: string;
  receiver_email: string[];
  email_status: string;
  is_favourite: boolean;
  size: number;
  tempFrom: any,
  tempTo: any,
}

export type TFilter = {
  title: string;
  type: "number" | "boolean" | "string" | "onchange";
  value: any;
  key: string;
  callback: (value: any) => boolean;
};
interface S {
  emailList: Array<any>;
  hasSorted: "default" | "sorted";
  // selectedFilter: Map<string, TFilter>;
  selectedFilter: any;
  otherDayValue: string;
  disabledFilterKey: Array<string>;
  isFilter: boolean;
  filteredEmail: Array<any>;


  selectedMail: any;
  searchTerm: any;
  sortPopOpen: any;
  filterPopOpen: any;
  tabState: any;
  settingsPopOpen: boolean;
  advanceSearchPop: boolean;
  username: any;
  password: any;
  startIndex: number;
  maxResults: any;
  starredEmails: any;
  selectedEmail: boolean;
  toggleFavoriteArray: boolean;
  advanceList: any;
  advanceListState: any;
  datePickerOpen: boolean;
  from: any;
  to: any;
  subject: string;
  has_word: string;
  size: string;
  has_attachment: any;
  isOpen: boolean;
  date: any;
  hasEmailResponseEmpty: boolean;
  advancedsearchList: any;
  normalSearchArray: any;
  onClickAdvanceSearch: boolean;
  demoAdvanceState: boolean;
  selectedSortingOption: any;
  sortByStateOption: boolean;
  optionsSortingState: any;
  normalSelectSearchMail: any;
  sort_column: any;
  sort_direction: any;
  wordtypedFrom: boolean;
  wordtypedTo: boolean;
  size_unit: string;
  size_condition: any;
  days: any;
  vault_id: any;
  save_set_id: any;
  isClickedMultipleFav: boolean;
  multipleFavArray: any;
  clickedSaveSetId: any;
  clickedVaultId: any;
  responseMultipleFavEmails: any;
  flag: boolean;
  dataSyncState: any;
  responseGetDataSync: any;
  responseSETDataSync: any;
  sortTempOption: any;
  sortTempDirection: any;
  sortTempColumn: any;
  filterTempCheckBox: any;
  width: any;
  height: any;
  isLoading: boolean,

  filterItems: any,
  tempFilterItems: any,
  requestToReset: boolean,
  isAppliedFiter: boolean,
  clearDataClicked: boolean,
  isShowUndo: boolean,
  tempFrom: any,
  tempTo: any
}

interface SS {
  id: any;
}

export default class Cache2Controller extends BlockComponent<Props, S, SS> {

  emailAPIRequestId: string = "";
  filterAPIRequestId: string = "";
  downloadEmailAPIRequestId: string = "";
  starredEmailAPIRequestId: string = "";


  scroller: any = React.createRef();
  emailPageEndRef = React.createRef<HTMLDivElement>();
  debounceTimer: any = "";
  postDataSyncKey: any = "";
  dataSyncGetAPIKey: any = "";
  MultipleFavEmailsKey: any = "";
  UndoMultipleFavEmailsKey: any = ''
  normalSearchKey: string = "";
  advancedsearchApiCallId: any;
  ToggleAPIKey: string = "";
  static contextType = ThemeContext;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {

      emailList: [],
      hasSorted: "default",
      selectedFilter: [],
      otherDayValue: "",
      disabledFilterKey: [],
      isFilter: false,
      filteredEmail: [],
      selectedMail: "",
      searchTerm: "",
      sortPopOpen: false,
      filterPopOpen: false,
      settingsPopOpen: false,
      advanceSearchPop: false,
      username: localStorage.getItem("user_name"),
      password: localStorage.getItem("user_password"),
      tabState: localStorage.getItem("user_name"),
      startIndex: 0,
      maxResults: RESULTS,
      starredEmails: [],
      selectedEmail: false,
      toggleFavoriteArray: false,
      advanceList: [],
      advanceListState: false,
      datePickerOpen: false,
      from: [],
      to: [],
      subject: "",
      has_word: "",
      size: "",
      has_attachment: false,
      isOpen: false,
      date: "",
      advancedsearchList: [],
      normalSearchArray: [],
      onClickAdvanceSearch: false,
      demoAdvanceState: false,
      selectedSortingOption: "",
      sortByStateOption: false,
      optionsSortingState: "",
      normalSelectSearchMail: "",
      sort_column: "",
      sort_direction: "",
      wordtypedFrom: false,
      wordtypedTo: false,
      size_unit: "",
      size_condition: "",
      days: "",
      vault_id: "",
      save_set_id: "",
      hasEmailResponseEmpty: false,
      multipleFavArray: [],
      isClickedMultipleFav: false,
      clickedSaveSetId: false,
      clickedVaultId: false,
      responseMultipleFavEmails: [],
      flag: false,
      dataSyncState: "",
      responseGetDataSync: {},
      responseSETDataSync: {},
      sortTempOption: "",
      sortTempDirection: "",
      sortTempColumn: "",
      filterTempCheckBox: [],
      width: "",
      height: "",
      isLoading: false,
      filterItems: [],
      tempFilterItems: [],
      requestToReset: false,
      isAppliedFiter: false,
      clearDataClicked: false,
      isShowUndo: false,
      tempFrom: '',
      tempTo: ''
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleResponse = (requestCallId: any, data: any) => {
    if (requestCallId === this.emailAPIRequestId) {
      this.handlerInboxEmail(data);
    }

    if (requestCallId === this.filterAPIRequestId) {
      this.handlerFilterEmail(data);
    }
    if (requestCallId === this.downloadEmailAPIRequestId) {
      this.handlerDownloadedEmail(data);
    }
    if (requestCallId === this.starredEmailAPIRequestId) {
      this.handlerStarredEmail(data);
    }

    if (requestCallId === this.advancedsearchApiCallId) {
      this.setAdvanceSearch(data);
    }
    if (this.ToggleAPIKey === requestCallId) {
      this.setState({
        toggleFavoriteArray: data,
      });
    }
    if (requestCallId === this.normalSearchKey) {
      this.handleNormalSearchResponse(data)
    }

    if (this.MultipleFavEmailsKey === requestCallId) {
      this.setState({
        responseMultipleFavEmails: data,
      });
      const ids = data.map(({id}:{id: any})=> id)
      const emails = [...this.state.emailList]
      const newDataEmails = this.reUpdateToggleFav(emails, ids,{ isMulti: true, isFav: true })
      this.setState({isShowUndo: true, emailList: newDataEmails})

      setTimeout(()=> this.setState({
        isShowUndo: false, 
        multipleFavArray: [],
        selectedMail: [],
      }), 5000)
    }

    /* istanbul ignore if */
    if (this.UndoMultipleFavEmailsKey === requestCallId) {
      const ids = data.map(({id}:{id: any})=> id)
      const emails = [...this.state.emailList]
      const newDataEmails = this.reUpdateToggleFav(emails, ids,{ isMulti: true, isFav: false })
      
      this.setState({
        multipleFavArray: [],
        selectedMail: [],
        isShowUndo: false,
        emailList: newDataEmails
      });
    }


    if (this.dataSyncGetAPIKey === requestCallId) {
      this.setState({ responseGetDataSync: this.state.dataSyncState });
    }
    if (this.postDataSyncKey === requestCallId) {
      this.setState({ responseSETDataSync: this.state.dataSyncState });
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleResponse(requestCallId, response.data)
    }
  }

  handleNormalSearchResponse = (data: any) => {
    if (this.state.searchTerm) {
      this.setState({
        normalSearchArray: data,
      });
    }
  }

  scrollToTop() {
    const emailListDiv = document.querySelector('[data-test-id="email_list"]');

    emailListDiv?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  componentDidMount = async () => {
    const emailObs = this.observerListener((entries) => {
      if (entries[0].isIntersecting) {
        //  no api, if empty response
        if (
          this.state.hasEmailResponseEmpty ||
          this.state.tabState === "Draft" ||
          this.state.tabState === "Sent Items"
        ) {
          return;
        }
        if (this.state.emailList?.length === 0) {
          this.fetchApiBaseOnTab();
        } else {
          // scroll
          this.setState(
            (prev) => ({
              startIndex: prev.startIndex + RESULTS,
            }),
            () => {
              this.fetchApiBaseOnTab();
            }
          );
        }
      }
    });
    if (this.emailPageEndRef.current) {
      emailObs.observe(this.emailPageEndRef.current);
    }
  };

  /* istanbul ignore next */
  fetchApiBaseOnTab = () => {
    switch (this.state.tabState) {
      case 'Starred': {
        this.fetchStarredEmail()
        break
      }
      case 'Downloaded': {
        this.fetchDownloadedEmail()
        break
      }
      case 'Sent Items':
      case 'Draft': {
        break
      }
      default: {
        this.fetchInboxEmails()
        break
      }
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if (prevProps.flag != this.props.flag) {
      this.setState({
        searchTerm: "",
        from: [],
        to: [],
        subject: "",
        has_word: "",
        size: "",
        date: "",
        has_attachment: false,
        days: "",
        size_condition: "",
        normalSelectSearchMail: [],
        normalSearchArray: [],
        isOpen: false,
        wordtypedFrom: false,
        wordtypedTo: false,
      });
    }
    if (
      prevState.selectedSortingOption != this.state.selectedSortingOption &&
      this.state.selectedSortingOption != ""
    ) {
      this.setState({ sort_direction: "asc" });
    }
  }

  observerListener = (
    callback: IntersectionObserverCallback
  ): IntersectionObserver => {
    return new IntersectionObserver(callback, {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    });
  };


  handleReset = (value: string, callback: () => void) => {
    this.setState(
      {
        tabState: value,
        selectedSortingOption: "",
        starredEmails: [],
        selectedMail: null,
        multipleFavArray: [],

        startIndex: 0,

        // new
        emailList: [],
        hasEmailResponseEmpty: false,
        sort_direction: "",
        sort_column: "",
        hasSorted: "default",
        filteredEmail: [],
        selectedFilter: [],
        isFilter: false,
        wordtypedFrom: false,
        wordtypedTo: false,
      },
      callback
    );
  };

  fetchInboxEmails = async (isFiltered: boolean = false) => {

    const filterData = this.getFilterData(this.state.filterItems, {
      days: this.state.date ? this.state.days : "",
      date: this.state.date,
      has_attachment: this.state.has_attachment
    });

    const body = {
      user_name: this.state.username,
      user_password: this.state.password,
      startIndex: this.state.startIndex,
      maxResults: RESULTS,
      has_word: this.state.has_word,
      subject: this.state.subject,
      from: get(this.state.from, '0', ''),
      to: this.state.to,
      size: this.state.size,
      size_unit: this.state.size_unit,
      size_condition: this.state.size_condition,
      ...(this.state.hasSorted === "sorted" && {
        sort_column: this.state.sort_column,
        sort_direction: this.state.sort_direction,
      }),
      ...filterData
    };

    const apiInterceptorArgs: IApiInterceptor = {
      endPoint: configJSON.apiEndPointURL,
      method: "POST",
      body: JSON.stringify(body),
    };
    if (isFiltered) {
      this.filterAPIRequestId = await this.apiInterceptor(apiInterceptorArgs);
    } else {
      this.emailAPIRequestId = await this.apiInterceptor(apiInterceptorArgs);
    }
  };

  fetchDownloadedEmail = async () => {
    let endPoint = `bx_block_api_search/api_searches/get_all_downloaded_emails?user_name=${this.state.username}&is_downloaded=true&startIndex=${this.state.startIndex}&maxResults=${RESULTS}&sort_column=downloaded_date`;

    if (this.state.hasSorted === "sorted") {
      endPoint = `${endPoint}&sort_direction=${this.state.sort_direction}&sort_column=${this.state.sort_column}`;
    }

    const apiInterceptorArgs: IApiInterceptor = {
      endPoint: endPoint,
      method: "GET",
    };
    this.downloadEmailAPIRequestId = await this.apiInterceptor(
      apiInterceptorArgs
    );
  };

  fetchStarredEmail = async () => {
    let endPoint = `bx_block_api_search/api_searches/get_all_favourite_emails?user_name=${this.state.username}&is_favourite=true&startIndex=${this.state.startIndex}&maxResults=${RESULTS}`;

    if (this.state.hasSorted === "sorted") {
      endPoint = `${endPoint}&sort_direction=${this.state.sort_direction}&sort_column=${this.state.sort_column}`;
    }

    const apiInterceptorArgs: IApiInterceptor = {
      endPoint,
      method: "GET",
    };
    this.starredEmailAPIRequestId = await this.apiInterceptor(
      apiInterceptorArgs
    );
  };

  reUpdateToggleFav = (emails: Array<any>, ids: Array<string> | string, multi?: { isMulti: boolean, isFav: boolean }) => {
    const emailIds = get(multi, 'isMulti', false) ? ids : [ids]
    const updatedEmails = emails.map((item) => {
      if (emailIds.includes(item.id)) {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            is_favourite: get(multi, 'isMulti', false) ? get(multi, 'isFav', true) : !get(item.attributes, 'is_favourite')
          }
        }

      } else return item
    })
    return updatedEmails
  }

  handleDataResponse = (emailList: Array<any>) => {
    return uniqBy(emailList, 'id')
  }

  handlerFilterEmail = (responseData: any) => {
    const isEmpty = responseData.length > 0 ? false : true;
    this.setState({
      emailList: this.handleDataResponse(responseData),
      hasEmailResponseEmpty: isEmpty,
      isLoading: false,
    });
  };

  handlerInboxEmail = (responseData: any) => {
    const isEmpty = responseData.length > 0 ? false : true;
    this.setState((prevState) => ({
      emailList: this.handleDataResponse([...prevState.emailList, ...responseData]),
      hasEmailResponseEmpty: isEmpty,
      isLoading: false,
    }));
  };

  handlerDownloadedEmail = (responseData: any) => {
    const isEmpty = responseData.length > 0 ? false : true;
    this.setState((prevState) => ({
      emailList: this.handleDataResponse([...prevState.emailList, ...responseData]),
      hasEmailResponseEmpty: isEmpty,
      isLoading: false,
    }));
  };

  handlerStarredEmail = (responseData: any) => {
    const isEmpty = responseData.length > 0 ? false : true;
    this.setState((prevState) => ({
      emailList: this.handleDataResponse([...prevState.emailList, ...responseData]),
      hasEmailResponseEmpty: isEmpty,
      isLoading: false,
    }));
  };


  handleFilterChange = (item: any) => {
    const { filterItems } = this.state
    let itemSelected = [...filterItems]
    const itemIndex = itemSelected.findIndex(obj => obj.value === item.value);
    if (item.key === 'otherDays') {
      this.setState({ otherDayValue: item.days })
    } else if (item.value === 'days') {
      this.setState({ otherDayValue: "" })
    }
    if (itemIndex === -1) {

      itemSelected.push(item)
    } else {
      if(itemSelected[itemIndex].key === item.key) {
        itemSelected.splice(itemIndex, 1)
      } else {
        itemSelected.splice(itemIndex, 1, item)
      }      
    }
    this.setState({ filterItems: itemSelected }, () => {
      const updatedFilterItems = this.state.filterItems.filter((item: any) => item.value !== 'days' || item.days !== '');

      // Update the state with the filtered items
      this.setState({ filterItems: updatedFilterItems });
    })

    return true
  }

  handlerFilter = (data: TFilter) => {
    const selectedFilter = new Map(this.state.selectedFilter);
    const timeFilters = [
      "Last 7 days",
      "Last 30 days",
      "Last 60 days",
      "otherDays",
    ];
    const attachmentFilter = "Has attachment";

    timeFilters.forEach((item) => {
      if (selectedFilter.has(item)) {

        selectedFilter.delete(item);
      }
    })
    // If the selected filter is one of the time-based options, disable all time-based options
    if (timeFilters.includes(data.title)) {
      selectedFilter.delete("otherDays");
      this.setState({ otherDayValue: "" });
      timeFilters.forEach((filter) => {
        if (filter === data.title) {
          selectedFilter.set(filter, data);
        } else {
          selectedFilter.delete(filter);
        }
      });
    }

    // If the selected filter is "Has Attachment," toggle its state
    if (data.title === attachmentFilter) {
      if (selectedFilter.has(attachmentFilter)) {
      } else {
        selectedFilter.set(attachmentFilter, {
          type: "boolean",
          title: "Has attachment",
          value: true, // Set this to true if you want to enable it by default
          key: "has_attatchments",
          callback: (item: any) => item === true,
        });
      }
    }
    if (data.title === "otherDays") {
      this.setState({
        otherDayValue: data.value, // Assuming data.value contains the value for "otherDays"
      });
    }
    this.setState({
      selectedFilter: selectedFilter,
    });
  };
  checkDateBetween(date: string, days: number) {
    const currentDate = moment().utc();
    const dateToCheck = moment(date).utc();
    const daysDifference = currentDate.diff(dateToCheck, "days");

    if (daysDifference <= days) {
      return true;
    }

    return false;
  }

  filterEmails = () => {
    const emailList = this.state.emailList;

    const filterData =
      [...this.state.selectedFilter].map(
        ([, value]) => {
          const newKey = value.key; const newValue = value.value; const callback = value.callback;
          return [newKey, newValue, callback];
        }
      );

    const filterEmail =
      emailList
        .filter(
          (item: any) => {
            return filterData.every(([key, value, callback]) => {
              return callback(item.attributes[key]);
            }
            );
          }
        );

    if (this.state.isFilter) {
      return filterEmail;
    }

    return emailList;
  };

  /* istanbul ignore next */
  getFilterData = (filter: any, advancedsearch?: any) => {
    let convertedObject = { days: 0, has_attachment: '' };
    for (const currentObject of filter) {
      const days = get(currentObject, 'days');
      const has_attachment = get(currentObject, 'has_attachment');
      const value = get(currentObject, 'value');

      if (value === 'days') {
        convertedObject.days = days;
      }

      if (value === 'has_attachment') {
        convertedObject.has_attachment = has_attachment;
      }
    }

    let data = {
      days: get(convertedObject, 'days', '') || get(advancedsearch, 'days', ''),
      date: get(convertedObject, 'date', '') || get(advancedsearch, 'date', ''),
      has_attachment: get(convertedObject, 'has_attachment', '') || get(advancedsearch, 'has_attachment', '')
    }

    const has_attachment = get(convertedObject, 'has_attachment')
    const days = get(convertedObject, 'days')


    if (days) {
      data.date = moment.utc().format('YYYY-MM-DD')
    }

    return data
  }

  handleApplyFilterBtn = () => {
    this.scrollToTop()
    const tempFilterItems = [...this.state.filterItems]
    this.setState({ tempFilterItems, startIndex: 0, filterPopOpen: false, selectedMail: null, normalSelectSearchMail: null }, () => {
      this.fetchInboxEmails(true);
    })
  };

  apiInterceptor = async (apiInterceptorArgs: IApiInterceptor) => {
    this.setState({ isLoading: true, })
    const header = {
      "Content-Type": apiInterceptorArgs.contentType || "application/json",
    };

    const endPoint = apiInterceptorArgs.endPoint;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiInterceptorArgs.method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      apiInterceptorArgs.body
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };


  handleToggleAPI = (

    save_set_id: any,
    vault_id: any,
    is_favourite: boolean
  ) => {
    const header = {
      "Content-Type": "application/json",
    };
    const payload = {
      record_ids: [{ vault_id: `${vault_id}`, save_set_id: `${save_set_id}` }],
      favourite_checked: is_favourite,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ToggleAPIKey = requestMessage.messageId; //reference

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiAddToFavoriteURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // payload
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  /* istanbul ignore next */
  handleStarClick = (emailData: any) => {
    const { starredEmails } = this.state;
    const findStarreredEmail = starredEmails.find(
      (i: any) => i.id === emailData.id
    );
    if (findStarreredEmail) {
      const filterStarredEmails = starredEmails.filter(
        (i: any) => i.id !== emailData.id
      );
      this.setState({ starredEmails: filterStarredEmails });
    } else {
      const newStarredEmail = [...starredEmails, emailData];
      this.setState({ starredEmails: newStarredEmail });
    }

    this.handleToggleAPI(
      emailData.attributes.save_set_id,
      emailData.attributes.vault_id,
      !emailData.attributes.is_favourite
    );

    let { tabState } = this.state;

    if (tabState === 'Starred') {
      const filterStarredEmails = this.state.emailList?.filter(
        (i: any) => i.id !== emailData.id
      );
      this.setState({ emailList: filterStarredEmails })
    } else {
      const newEmailList = this.reUpdateToggleFav(this.state.emailList, emailData.id)
      this.setState({ emailList: newEmailList })
    }

  };

  handleTabUpdate = (value: string) => {
    this.setState({
      advanceListState: !this.state.advanceListState,
      isOpen: false,
      filterPopOpen: false,
      normalSelectSearchMail: null,
      startIndex: 0,
      flag: !this.state.flag,
      filterItems: [],
      tempFilterItems: [],
      otherDayValue: '',
      selectedMail: null,
      // reset advance search input
      has_word: '',
      subject: '',
      from: [],
      to: [],
      size: '',
      size_unit: '',
      size_condition: '',
      date: '',
      has_attachment: '',
      days: '',
      tempTo: '',
      tempFrom: ''
    });

    if (value === "Starred") {
      this.handleReset(value, () => this.fetchStarredEmail());
    } else if (value === "Downloaded") {
      this.handleReset(value, () => this.fetchDownloadedEmail());
    } else if (value === "Sent Items") {
      this.setState({
        tabState: value,

        selectedMail: null,

        starredEmails: [],

        multipleFavArray: [],

        selectedSortingOption: "",

        emailList: [],
        filteredEmail: [],
        selectedFilter: new Map(),
        isFilter: false,
      });
    } else if (value === "Draft") {
      this.setState({
        tabState: value,

        selectedMail: null,

        starredEmails: [],

        multipleFavArray: [],

        selectedSortingOption: "",

        emailList: [],
        filteredEmail: [],
        selectedFilter: new Map(),
        isFilter: false,
        otherDayValue: "",
      });
    } else if (value === this.state.username) {
      this.setState({ tabState: 'inbox' })
      this.handleReset(value, () => this.fetchInboxEmails());
    }
    this.setState({
      sortTempColumn: "",
      sortTempDirection: "",
      sortTempOption: "",
    });
  };

  handlePopBtn = (value: string) => {
    switch (value) {
      case "sort":
        this.setState({ filterPopOpen: false });
        this.setState({ sortPopOpen: !this.state.sortPopOpen });
        if (this.state.selectedSortingOption) {
          this.setState({
            sortTempOption: this.state.selectedSortingOption,
            sortTempDirection: this.state.sort_direction,
            sortTempColumn: this.state.sort_column,
          });
        }
        break;
      case "filter":
        this.setState({ sortPopOpen: false });
        this.setState({ filterPopOpen: !this.state.filterPopOpen });
        break;
      default:
        return;
    }
  };

  handleSortPopClose = () => {
    this.setState({
      sortPopOpen: false,
      //  selectedSortingOption : "",
      selectedSortingOption: this.state.sortTempOption,
      sort_direction: this.state.sortTempDirection,
      sortTempColumn: this.state.sort_column,
      sortTempOption: "",
    });
  };
  handleSortingOptionChange = (sortingOption: string) => {
    if (sortingOption == "Date") {
      sortingOption = "sent_dt";
      this.setState({
        selectedSortingOption: sortingOption,
        sort_column: "sent_dt",
      });
      this.toggleSortOrder();
      return;
    }

    this.setState({
      selectedSortingOption: sortingOption,
      sort_column: sortingOption.toLowerCase(),
    });
    this.toggleSortOrder();
  };
  handleSortingFunction = () => {
    if (this.state.tabState === "Starred") {
      this.setState(
        {
          sortByStateOption: true,
          sortPopOpen: false,

          selectedMail: null,

          emailList: [],
          hasSorted: "sorted",
          startIndex: 0,
        },
        () => {
          this.fetchStarredEmail();
        }
      );
    } else if (this.state.tabState === "Downloaded") {
      this.setState(
        {
          sortByStateOption: true,
          sortPopOpen: false,

          selectedMail: null,

          emailList: [],
          hasSorted: "sorted",
          startIndex: 0,
        },
        () => {
          this.fetchDownloadedEmail();
        }
      );
      // Call the appropriate sorting function
    } else {
      this.setState(
        {
          sortByStateOption: true,
          sortPopOpen: false,

          selectedMail: null,

          emailList: [],
          hasSorted: "sorted",
          startIndex: 0,
        },
        () => {
          this.fetchInboxEmails();
        }
      );
    }
    this.setState({
      sortTempColumn: "",
      sortTempDirection: "",
      sortTempOption: "",
    });
  };
  defaultBtnSort = () => {
    this.setState({
      selectedSortingOption: "",
      sort_direction: "",
      sort_column: "",
    });
  };

  toggleSortOrder = () => {
    this.setState((prevState) => ({
      sort_direction: prevState.sort_direction == "desc" ? "asc" : "desc",
    }));
  };

  handleFilterPopClose = () => {
    this.setState({
      filterPopOpen: false, filterItems: this.state.tempFilterItems,
      otherDayValue: get(find(this.state.tempFilterItems, ((item: any) => item.key === 'otherDays')), 'days')
    });
  };

  handleClearFilters: Function = () => {
    this.setState({ filterItems: [], otherDayValue: "" });
  };
  // //////////////////////////////////
  handleDateChange = (dateEvent: any) => {
    const extractedDate = moment(dateEvent).format("YYYY-MM-DD");
    this.setState({ date: extractedDate });
    this.setState({ datePickerOpen: false });
  };

  handleInput = (e: any) => {
    const { name, value } = e.target;
    if (name === "days") {
      this.setState({
        days: e.target.value,
      });
    }
    if (name === "size_condition") {
      this.setState({
        size_condition: e.target.value,
      });
    }
    if (name === "from") {
      this.setState({
        from: value,
        wordtypedFrom: e.target.value !== "",
      });
    }
    if (name === "to") {
      this.setState({
        to: e.target.value,
        wordtypedTo: e.target.value !== "",
      });
    }
    if (name === "subject") {
      this.setState({
        subject: e.target.value,
      });
    }
    if (name === "has_word") {
      this.setState({
        has_word: e.target.value,
      });
    }
    if (name === "size") {
      this.setState({
        size: e.target.value,
      });
    }
    if (name === "date") {
      this.setState({
        date: this.state.date,
        // date : this.state.selectedDate
      });
    }
    if (name === "has_attachment") {
      this.setState((prevState) => ({
        has_attachment: !prevState.has_attachment,
      }));
    }
    if (name === "size_unit") {
      this.setState({
        size_unit: e.target.value,
      });
    }
  };
  handleDatePicker = () => {
    this.setState({ datePickerOpen: !this.state.datePickerOpen });
  };
  /* istanbul ignore next */
  handleAdvanceSearchFunction = async (data: any) => {
    const header = {
      "Content-Type": "application/json",
    };

    const convertedData = { ...data, days: data.date ? data.days : "" }

    this.setState({ ...convertedData, isLoading: true});

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.advancedsearchApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_api_search/api_searches/search_data`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({...convertedData, from: get(data, 'from.0', '') })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return this.advancedsearchApiCallId;
  };

  setAdvanceSearch = (responseJson: any) => {
    this.setState({
      advancedsearchList: responseJson,
      onClickAdvanceSearch: false,
      advanceSearchPop: false,
      datePickerOpen: false,
      isLoading: false,
      hasEmailResponseEmpty: !size(responseJson),
      emailList: responseJson,
    });
  };

  //////////////////////////////
  handleSearchTerm = (value: any) => {
    this.setState({ searchTerm: value });
    this.setState({ selectedMail: null });
    this.handleNormalSearch(value);
  };
  ////////////////////////////////
  debounce = (func: any, delay: any) => {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      func.bind(this)();
    }, delay);
  };

  handleInputChange = (event: any) => {
    const inputValue = event.target.value;
    this.setState({ searchTerm: inputValue, selectedMail: null });

    // Call the debounced handler
    if (inputValue) {
      this.handleInputChangeDebounced(inputValue);
    } else {
      this.setState({ normalSearchArray: [] });
    }
  };
  handleInputChangeDebounced = (value: any) => {
    this.debounce(() => {
      this.handleNormalSearch(value);
    }, 500);
  };

  toggleSearchBox = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  handleSettingsPop = () => {
    this.setState({ settingsPopOpen: !this.state.settingsPopOpen });
    this.dataSyncGetAPI();
  };
  toggleCard = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleAdvanceSearchPop = () => {
    this.setState({
      isOpen: false,
      // advanceSearchPop: !this.state.advanceSearchPop,
      advanceSearchPop: true,
      onClickAdvanceSearch: true,
      datePickerOpen: false,
      selectedMail:null
      ////////////////////////
    });
  };
  handleNormalSearch = (e: any) => {
    const header = {
      "Content-Type": "application/json",
    };
    const httpBody = {
      user_name: this.state.username,
      user_password: this.state.password,
      maxResults: "",
      has_word: e,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.normalSearchKey = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_api_search/api_searches/search_data`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.normalSearchKey;
  };

  formatDate = (timestamp: any) => {
    const date = new Date(timestamp);
    const now = new Date();

    if (date.toDateString() === now.toDateString()) {
      // Email received today, show time only
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else if (date.getFullYear() === now.getFullYear()) {
      // Email received in the current year, show date and month
      // return date.toLocaleDateString([], { day: 'numeric', month: 'short' });
      const day = date.toLocaleString("default", { day: "2-digit" });
      const month = date.toLocaleString("default", { month: "short" });
      return `${day} ${month}`;
    } else {
      // Email received in a previous year, show full date
      const day = date.toLocaleString("default", { day: "2-digit" });
      const month = date.toLocaleString("default", { month: "short" });
      return `${day} ${month} ${date.getFullYear()}`;
    }
  };

  clearFilterAdvanceSearch = () => {
    this.setState({
      from: [],
      to: [],
      subject: "",
      has_word: "",
      size: "",
      date: "",
      has_attachment: false,
      days: "",
      size_condition: "",
      wordtypedFrom: false,
      wordtypedTo: false,
      startIndex: 0,
      tempFrom: '',
      tempTo: '',
    });
  };

  openOutLookReply = (sender_email: string) => {
    const emailLink = document.createElement("a");
    emailLink.href = `mailto:${sender_email}`;
    emailLink.click();
  };
  openOutLookForward = (
    sender_email: string,
    subject: string,
    body: string
  ) => {
    const emailLink = document.createElement("a");
    emailLink.href = `mailto:?subject=${encodeURIComponent(
      `Fwd: ${subject}`
    )}&body=${encodeURIComponent(body)}`;
    emailLink.click();
  };
  openOutlookCompose = () => {
    const emailLink = document.createElement("a");
    emailLink.href = `mailto:`;
    emailLink.click();
  };

  handleIndividualDownload = async (vault_id: string, save_set_id: string) => {
    try {
      const response = await fetch(
        `${baseURL.baseURL}/bx_block_api_search/api_searches/download?user_name=${this.state.username}&user_password=${this.state.password}&vault_id=${vault_id}&save_set_id=${save_set_id}`,
        { method: "GET" }
      );

      const blob = new Blob([response.url], { type: "message/rfc822" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.href = response.url;
      a.download = "example.eml";
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error: any) {
      console.log("Api Error" + JSON.stringify(error));
    }
  };

  handleMultipleFavEmails = (save_set_id: string, vault_id: string) => {
    this.setState({
      isClickedMultipleFav: true,
      clickedSaveSetId: save_set_id,
      clickedVaultId: vault_id,
    });
    const check = this.state.multipleFavArray.find(
      (e: any) => e.save_set_id == save_set_id
    );
    if (check) {
      const temp = this.state.multipleFavArray.filter(
        (e: any) => e.save_set_id !== save_set_id
      );
      this.setState({ multipleFavArray: [...temp] });
    } else {
      const temp = [...this.state.multipleFavArray, { save_set_id, vault_id }];
      this.setState({ multipleFavArray: [...temp] });
    }
  };
  MulptileFavAPIFunction = () => {
    const header = {
      "Content-Type": "application/json",
    };
    const body = {
      record_ids: this.state.multipleFavArray,
      favourite_checked: true,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.MultipleFavEmailsKey = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_api_search/api_searches/toggle_favourites`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // payload
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  UndoMulptileFavAPIFunction = () => {
    const header = {
      "Content-Type": "application/json",
    };
    const body = {
      record_ids: this.state.multipleFavArray,
      favourite_checked: false,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.UndoMultipleFavEmailsKey = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_api_search/api_searches/toggle_favourites`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // payload
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  CancelMultipleFav = () => {
    this.setState({ multipleFavArray: [], selectedMail: [] });
  };

  dataSyncGetAPI = () => {
    const header = {
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.dataSyncGetAPIKey = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_api_search/api_searches/get_sync_frequency?user_name=${this.state.username}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  dataSyncPostAPI = (e: any) => {
    this.setState({ dataSyncState: e.target.value });
    const header = {
      "Content-Type": "application/json",
    };
    const body = {
      user_name: "carly.simon",
      sync_frequency: `${this.state.dataSyncState}`, // 0: monthly, 1 weekly
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postDataSyncKey = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_api_search/api_searches/set_sync_frequency`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // payload
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

}
